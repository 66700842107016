import { reactive } from 'vue';

import mixpanel from 'mixpanel-browser';

import { AuthState } from '@core/utils';

import { useLogger } from '@/utils';
import { useAuthService } from '@/modules/Auth/services/AuthService';

export const AnalyticsState = reactive({
  initialized: false,
});

const { logInfo } = useLogger('Mixpanel');

export const initAnalytics = (user) => {
  const impersonatedTail = AuthState.impersonatedUser?.loggedAsUserId ? '_impersonated' : '';
  const id = `${user.id}${impersonatedTail}`;
  const role = user.type === 'team_member' ? user.default.role_name : user.type;

  mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);
  mixpanel.identify(id);
  mixpanel.people.set({
    'User Id': id,
    'First Name': user.first_name,
    'Last Name': user.last_name,
    $name: `${user.first_name} ${user.last_name}${impersonatedTail}`,
    $email: user.email,
    Language: user.preferences.language,
    Currency: user.preferences.currency,
  });
  mixpanel.register({
    'Account ID': user.default?.account_id || null,
    role,
  });

  AnalyticsState.initialized = true;
  logInfo('Initialized');
};

export const track = (event) => {
  const { user } = useAuthService();
  if (!user?.id
    || !!AuthState.impersonatedUser?.loggedAsUserId
    || process.env.NODE_ENV !== 'production') {
    logInfo('Skipped analytics action');
    return;
  }

  if (!AnalyticsState.initialized) {
    logInfo('Init');
    initAnalytics(user);
  }

  logInfo(`${event} event`);
  mixpanel.track(`AS - ${event}`, {}, { send_immediately: true });
};
